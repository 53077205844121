/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.10.0-v202502080341-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqNotificationConfigurationsApiClass {
 
   constructor() {}

  /**
   * @summary Delete a notification configuration
   * @param {string} id - The Seeq ID of the notifiable item
   */
  public deleteNotificationConfiguration(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/notification-configurations/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Get a notification configuration
   * @param {string} id - The Seeq ID of the notifiable item
   */
  public getNotificationConfiguration(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/notification-configurations/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NotificationConfigurationOutputV1>;
  }

  /**
   * @summary Create or update a notification configuration on a condition monitor
   * @param {string} id - The Seeq ID of the condition monitor
   */
  public setNotificationConfigurationForConditionMonitor(
    body: models.ConditionMonitorNotificationConfigurationInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/notification-configurations/condition-monitors/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NotificationConfigurationOutputV1>;
  }

  /**
   * @summary Create or update a notification configuration on a report
   * @param {string} id - The Seeq ID of the report
   */
  public setNotificationConfigurationForReport(
    body: models.ReportNotificationConfigurationInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/notification-configurations/reports/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NotificationConfigurationOutputV1>;
  }

  /**
   * @summary Unsubscribe the current user from the notification on the specified item
   * @param {string} id - The ID of the item to unsubscribe from
   */
  public unsubscribeFromItem(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/notification-configurations/unsubscribe/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

}


export const sqNotificationConfigurationsApi = new sqNotificationConfigurationsApiClass();
